import React, { useState, useEffect } from 'react'

const RegisterKitForm = () => {

	const [state, setState] = React.useState({
		kitCode: '',
		kitHolder: 'iam',
		name: '',
		email: '',
	});
	const [currentStep, setCurrentStep] = useState(1);

	function toggleInputState(element) {
		element.parentNode.classList.remove('active')
		if (element.value === "") element.parentNode.classList.remove('stay')
	}

	useEffect(() => {
		if (typeof window !== 'undefined') {
			var floatingInputs = document.querySelectorAll('.product-register-form-input-field');

			floatingInputs.forEach(function (element) {
				element.addEventListener('focus', () => { element.parentNode.classList.add('active', 'stay'); });
				element.addEventListener('blur', () => { toggleInputState(element) });
			});
		}
		return () => {
			if (typeof window !== 'undefined') {
				floatingInputs.forEach(function (element) {
					element.removeEventListener('focus', () => { element.parentNode.classList.add('active', 'stay'); });
					element.removeEventListener('blur', () => { toggleInputState(element) });
				});
			}
		};
	});

	function encode(data) {
		return Object.keys(data)
			.map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
			.join("&");
	}

	function handleChange(event) {
		event.target.parentNode.classList.remove('error');
		const {
			target: { name, value }
		} = event;
		setState({ ...state, [name]: value });
	}

	function handleSubmit(e) {
		e.preventDefault();
		if (validateInputs() == false) return false
		fetch("/", {
			method: "POST",
			headers: { "Content-Type": "application/x-www-form-urlencoded" },
			body: encode({ "form-name": "register-kit", ...state })
		})
			.then(() => setCurrentStep(currentStep + 1))
			.catch(error => alert(error));
	}

	function validateInputs() {
		var fields = document.querySelectorAll('.product-register-form input[type=text]'),
			valid = true

		fields.forEach(function (element) {
			if (element.value == '') {
				element.parentNode.classList.add('error')
				valid = false
			}
		});
		return valid
	}

	function _next() {
		if (validateInputs() == false) return false

		setCurrentStep(currentStep + 1)
	}

	return (
		<form
			name="register-kit"
			className="product-register-form"
			action="/"
			data-netlify="true"
			data-netlify-honeypot="bot-field"
			onSubmit={handleSubmit}>
			<input type="hidden" name="form-name" value="register-kit" />
			<Step1
				currentStep={currentStep}
				handleChange={handleChange}
				kitCode={state.kitCode}
				kitHolder={state.kitHolder}
				next={_next}
			/>
			<Step2
				currentStep={currentStep}
				handleChange={handleChange}
				kitCode={state.kitCode}
				kitHolder={state.kitHolder}
				name={state.name}
				email={state.email}
			/>
			<Success
				currentStep={currentStep}
			/>
		</form>
	)
}

function Step1(props) {
	if (props.currentStep !== 1) {
		return null
	}
	return (
		<>
			<input type="hidden" name="name" />
			<input type="hidden" name="email" />
			<fieldset className="product-register-form-input">
				<label className="product-register-form-input-label" htmlFor="kitCode">Unique Kit ID</label>
				<input className="product-register-form-input-field" type="text" name="kitCode" value={props.kitCode} onChange={props.handleChange} />
			</fieldset>
			<fieldset className="product-register-form-holder">
				<div className="product-register-form-holder-item">
					<input className="product-register-form-holder-item-input" type="radio" value="iam" name="kitHolder" defaultChecked="checked" onChange={props.handleChange} />
					<label className="product-register-form-holder-item-label" htmlFor="iam">I&nbsp;will be&nbsp;using this kit</label>
				</div>
				<div className="product-register-form-holder-item">
					<input className="product-register-form-holder-item-input" type="radio" value="iam" name="kitHolder" onChange={props.handleChange} />
					<label className="product-register-form-holder-item-label" htmlFor="someone">This kit is&nbsp;for someone else</label>
				</div>
			</fieldset>
			<button className="product-register-form-submit" type="button" onClick={props.next}>Next</button>
		</>
	);
}

function Step2(props) {
	if (props.currentStep !== 2) {
		return null
	}
	return (
		<>
			<input type="hidden" name="kitCode" value={props.kitCode} />
			<input type="hidden" name="kitHolder" value={props.kitHolder} />
			<fieldset className="product-register-form-input">
				<label className="product-register-form-input-label" htmlFor="name">Holder name</label>
				<input className="product-register-form-input-field" type="text" name="name" value={props.name} onChange={props.handleChange} />
			</fieldset>
			<fieldset className="product-register-form-input">
				<label className="product-register-form-input-label" htmlFor="email">E-mail</label>
				<input className="product-register-form-input-field" type="email" name="email" value={props.email} onChange={props.handleChange} />
			</fieldset>
			<div netlify-recaptcha></div>
			<button className="product-register-form-submit">Register</button>
		</>
	);
}

function Success(props) {
	if (props.currentStep !== 3) {
		return null
	}
	return (
		<>
			Thank you!<br />
			Your kit has been successfully registered
		</>
	);
}

export default RegisterKitForm