import React, { useEffect } from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import Img from "gatsby-image"
import Div100vh from 'react-div-100vh'
import Player from '~/components/Player'
import RegisterKitForm from '~/components/RegisterKitForm'

import SEO from '~/components/seo'

import '~/styles/ProductPage.scss'

import YoutubeIcon from '~/svgs/youtube.svg'
import InstagramIcon from '~/svgs/instagram.svg'
import FacebookIcon from '~/svgs/facebook.svg'

import Scroll from '~/svgs/scroll.svg'

import Product from '~/svgs/product.svg'
import TabletIcon from '~/svgs/tablet.svg'
import PlayIcon from '~/svgs/play.svg'
import ListIcon from '~/svgs/list.svg'

import CoughIcon from '~/svgs/cough.svg'
import FeverIcon from '~/svgs/fever.svg'
import LungIcon from '~/svgs/lung.svg'

import Step1Icon from '~/images/test-step-1.svg'
import Step2Icon from '~/images/test-step-2.svg'
import Step3Icon from '~/images/test-step-3.svg'
import Step4Icon from '~/images/test-step-4.svg'

import Cert1 from '~/certificates/9001-IAS.pdf'
import Cert2 from '~/certificates/13485-IAS.pdf'
import Cert3 from '~/certificates/MSCB-171.pdf'
import Cert5 from '~/certificates/FDA-Registration.pdf'
import Cert6 from '~/certificates/CMDN-0005_BostonBiolabs-IgG+IgM.pdf'
import Cert7 from '~/certificates/MDG_Certificate_registration_BostonBiolabs-IgM.pdf'
import Cert8 from '~/certificates/ZVA_GLM-sia-IgM.pdf'
import Cert9 from '~/certificates/ZVA_GLM-IgG-IgM.pdf'

const IndexPage = ({ data }) => {

  var header, headerTop;

  function toggleScrollClass() {
    if (typeof window !== 'undefined') {
      if (!header || !headerTop) {
        header = document.getElementById('header');
        headerTop = parseInt(window.getComputedStyle(header).getPropertyValue('top'));
      }
      if (window.scrollY > headerTop) {
        header.classList.remove('global-header-intro');
      }
      else {
        headerTop = parseInt(window.getComputedStyle(header).getPropertyValue('top'));
        header.classList.add('global-header-intro');
      }
    }
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      toggleScrollClass();
      window.addEventListener('scroll', toggleScrollClass);
    }
    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('scroll', toggleScrollClass);
        header.classList.remove('global-header-intro');
      }
    };
  });

  return (
    <>
      <SEO title="COVID–19 Home Test Kit" keywords={['hometest', 'test', 'testkit', 'kit', 'covid', 'covid-19']} />
      <Helmet
        bodyAttributes={{
          class: "product-page"
        }}
      />
      <main className="product">
        <Div100vh as="section" className="product-intro" id="intro">
          <div className="product-intro-inner">
            <Img className="product-intro-image" fluid={data.covidIntro.childImageSharp.fluid} imgStyle={{ objectFit: "contain" }} alt="" />
            <div className="product-intro-slide-num">01</div>
            <div className="product-intro-text">
              <div className="product-intro-text-title">
                COVID&mdash;19
              </div>
              <p>
                Home test kit for detection
                of&nbsp;IgM antibody SARS-CoV-2
              </p>
            </div>
            <AnchorLink className="product-intro-scroll" href='#about'>
              <Scroll alt="Scroll down" className="product-intro-scroll-icon" />
            </AnchorLink>
            <AnchorLink className="product-anchor-link product-instruction-link product-intro-instruction-link" href='#instruction'>
              <TabletIcon className="product-anchor-link-icon" />
              <span className="product-anchor-link-text">Quick start instructions</span>
            </AnchorLink>
            <ul className="product-intro-social">
              <li className="product-intro-social-item"><a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/channel/UCEPVOXVA3XnI3zsE79ubcAQ/"><YoutubeIcon className="product-intro-social-item-icon product-intro-social-item-icon-instagram" /></a></li>
              <li className="product-intro-social-item"><a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/boston_bio_lab_inc/"><InstagramIcon className="product-intro-social-item-icon product-intro-social-item-icon-youtube" /></a></li>
              <li className="product-intro-social-item"><a target="_blank" rel="noopener noreferrer" href="https://facebook.com/bostonbio.lab"><FacebookIcon className="product-intro-social-item-icon product-intro-social-item-icon-facebook" /></a></li>
            </ul>
          </div>
        </Div100vh>
        <section className="product-about" id="about">
          <div className="product-page-title-1 product-about-title">
            Diagnostic kit for detection of&nbsp;IgM antibodies from COVID-19
          </div>
          <div className="product-page-column">
            <p>
              This kit is&nbsp;used for early detection of&nbsp;COVID-19
              (also known as&nbsp;SARS-CoV-2 and novel coronavirus)
            </p>
            <p>
              When the antibodies that are present in&nbsp;COVID-19 come into contact with the chemicals in&nbsp;the cassette, it&nbsp;causes a&nbsp;chemical reaction that makes the
              test strip change color.
            </p>
            <div className="product-about-actions">
              {/*<Link to="/covid-19-home-test-kit" className="product-buy">
                <Product className="product-buy-icon" />
                <span className="product-buy-title">Buy kit</span>
      </Link>*/}
              <AnchorLink className="product-anchor-link product-instruction-link" href='#instruction'>
                <TabletIcon className="product-anchor-link-icon" />
                <span className="product-anchor-link-text">Quick start instructions</span>
              </AnchorLink>
            </div>
          </div>
          <div className="product-page-column">
            <p>
              The specific process is&nbsp;as&nbsp;follows: A&nbsp;monoclonal mouse anti-human IgM conjugated to&nbsp;colloidal gold andembedded in&nbsp;the sample pad reacts with the IgM antibody in&nbsp;COVID-19 present in&nbsp;whole blood, serum or&nbsp;plasma forming a&nbsp;conjugate/COVID-19 antibody complex. As&nbsp;the mixture is&nbsp;allowed to&nbsp;migrate along the test strip, the conjugate/ COVID-19 antibody complex is&nbsp;captured by&nbsp;recombinant COVID-19 antigen immobilized on&nbsp;a&nbsp;membrane forming a&nbsp;colored test line in&nbsp;the test region.
          </p>
          </div>
        </section>
        <section className="product-disease" id="disease">
          <div className="product-page-title-1 product-disease-title">
            What is&nbsp;COVID-19?
        </div>
          <div className="product-page-column">
            <p>
              &laquo;Coronavirus Disease 2019&raquo; (referred to&nbsp;widely as&nbsp;COVID-19, or&nbsp;coronavirus) is&nbsp;the respiratory disease caused by&nbsp;the &laquo;SARS-CoV-2&raquo; virus. It&nbsp;was recently identified in&nbsp;Wuhan, China, and has spread globally. There has been an&nbsp;increasing number of&nbsp;cases reported in&nbsp;the United States, and it&nbsp;has become a&nbsp;global public health emergency. As&nbsp;of&nbsp;March&nbsp;11, the World Health Organization (WHO) classified COVID-19&nbsp;as a&nbsp;pandemic.
          </p>
          </div>
          <div className="product-page-column">
            <div className="product-disease-symptoms">
              <div className="product-disease-symptoms-item">
                <div className="product-disease-symptoms-item-icon product-disease-symptoms-item-icon-purple"><CoughIcon /></div>
                <div className="product-disease-symptoms-item-title">Cough</div>
              </div>
              <div className="product-disease-symptoms-item">
                <div className="product-disease-symptoms-item-icon product-disease-symptoms-item-icon-blue"><LungIcon /></div>
                <div className="product-disease-symptoms-item-title">Difficulty<br />breathing</div>
              </div>
              <div className="product-disease-symptoms-item">
                <div className="product-disease-symptoms-item-icon product-disease-symptoms-item-icon-yellow"><FeverIcon /></div>
                <div className="product-disease-symptoms-item-title">Fever</div>
              </div>
            </div>
            <p>
              <strong>Some common symptoms</strong> of&nbsp;the disease include cough, fever and difficulty breathing. In&nbsp;more serious cases, infection may cause pneumonia, severe acute respiratory syndrome, renal failure, and even death. (Refer to&nbsp;CDC&nbsp;or WHO)
          </p>
          </div>
        </section>
        <section className="product-instruction" id="instruction">
          <div className="product-page-title-1 product-instruction-title">
            Quick start instructions
            <AnchorLink className="product-anchor-link product-instruction-title-anchor-link" href='#video'>
              <PlayIcon className="product-anchor-link-icon" />
              <span className="product-anchor-link-text">Watch video</span>
            </AnchorLink>
          </div>
          <div className="product-instruction-container">
            <div className="product-instruction-step">
              <img src={Step1Icon} alt="Step 1" className="product-instruction-step-icon" />
              <div className="product-instruction-step-name">STEP 01</div>
              <div className="product-instruction-step-text">Take a&nbsp;blood sample from your finger</div>
            </div>
            <div className="product-instruction-step">
              <img src={Step2Icon} alt="Step 2" className="product-instruction-step-icon" />
              <div className="product-instruction-step-name">STEP 02</div>
              <div className="product-instruction-step-text">Using the pipette, deposit 2&ndash;3 drops of&nbsp;blood into the opening marked&nbsp;S on&nbsp;the cassette</div>
            </div>
            <div className="product-instruction-step">
              <img src={Step3Icon} alt="Step 3" className="product-instruction-step-icon" />
              <div className="product-instruction-step-name">STEP 03</div>
              <div className="product-instruction-step-text">Add 2&ndash;3 drops of&nbsp;the Saline Solution into the opening marked&nbsp;S on&nbsp;the cassette</div>
            </div>
            <div className="product-instruction-step">
              <img src={Step4Icon} alt="Step 4" className="product-instruction-step-icon" />
              <div className="product-instruction-step-name">STEP 04</div>
              <div className="product-instruction-step-text">After 15&nbsp;minutes, results will display in&nbsp;the detection window</div>
            </div>
          </div>
        </section>
        {data.allYoutubeVideo.edges && (
          <section className="product-video" id="video">
            <div className="product-page-title-1 product-video-title">
              Watch video
          </div>
            <div className="product-video-container">
              {data.allYoutubeVideo.edges.map(({ node: video }, key) => (
                <div className="product-video-item" key={key}>
                  <Player id={video.videoId} imageSize='maxresdefault' styles={{
                    width: "100%",
                    height: "100%"
                  }} />
                </div>
              ))}
            </div>
            <div className="product-video-info">
              <a className="product-anchor-link product-video-info-link-instructions" href="https://www.youtube.com/watch?v=i-8nVY0V7cE&list=PLBKkRxFo1Euq6LxN23B6n5hYVdAR7TUMT" target="_blank" rel="noopener noreferrer">
                <ListIcon className="product-anchor-link-icon product-video-info-link-instructions-icon" />
                <span className="product-anchor-link-text">Watch video instructions</span>
              </a>
              <a className="product-anchor-link product-video-info-link" href="https://www.youtube.com/channel/UCEPVOXVA3XnI3zsE79ubcAQ" target="_blank" rel="noopener noreferrer">
                <PlayIcon className="product-anchor-link-icon" />
                <span className="product-anchor-link-text">Watch more videos on&nbsp;our Youtube channel</span>
              </a>
              <p>
                On&nbsp;our channel, we&nbsp;publish videos about our products as&nbsp;well as&nbsp;other useful tips about health and disease prevention
              </p>
            </div>
          </section>
        )}
        <section className="product-register" id="register">
          <div className="product-register-inner">
            <div className="product-page-title-1 product-register-title">
              Register your kit
            </div>
            <RegisterKitForm />
          </div>
        </section>
        <section className="product-certificates" id="certificates">
          <div className="product-page-title-1 product-certificates-title">
            Certificates
          </div>
          <div className="product-certificates-container">
            <a className="product-certificates-item" target="_blank" href={Cert1}>
              <div className="product-certificates-item-name">9001 IAS</div>
              <div className="product-certificates-item-description">Certificate of registration</div>
              <div className="product-certificates-item-download">9001-IAS.pdf</div>
            </a>
            <a className="product-certificates-item" target="_blank" href={Cert2}>
              <div className="product-certificates-item-name">13485 IAS</div>
              <div className="product-certificates-item-description">Certificate of registration</div>
              <div className="product-certificates-item-download">13485-IAS.pdf</div>
            </a>
            <a className="product-certificates-item" target="_blank" href={Cert3}>
              <div className="product-certificates-item-name">MSCB-171</div>
              <div className="product-certificates-item-description">Certificate of accreditation</div>
              <div className="product-certificates-item-download">MSCB-171.pdf</div>
            </a>
            <a className="product-certificates-item" target="_blank" href={Cert5}>
              <div className="product-certificates-item-name">EUA201263</div>
              <div className="product-certificates-item-description">Certificate of registration</div>
              <div className="product-certificates-item-download">FDA-Registration.pdf</div>
            </a>
            <a className="product-certificates-item" target="_blank" href={Cert6}>
              <div className="product-certificates-item-name">CMDN-0005</div>
              <div className="product-certificates-item-description">Certificate of registration</div>
              <div className="product-certificates-item-download">CMDN-0005_BostonBiolabs-IgG+IgM.pdf</div>
            </a>
            <a className="product-certificates-item" target="_blank" href={Cert7}>
              <div className="product-certificates-item-name">CMDN-0002</div>
              <div className="product-certificates-item-description">Certificate of registration</div>
              <div className="product-certificates-item-download">MDG_Certificate_registration_BostonBiolabs-IgM.pdf</div>
            </a>
            <a className="product-certificates-item" target="_blank" href={Cert8}>
              <div className="product-certificates-item-name">7-1/898</div>
              <div className="product-certificates-item-description">Certificate of registration</div>
              <div className="product-certificates-item-download">ZVA_GLM-sia-IgM.pdf</div>
            </a>
            <a className="product-certificates-item" target="_blank" href={Cert9}>
              <div className="product-certificates-item-name">7-1/680</div>
              <div className="product-certificates-item-description">Certificate of registration</div>
              <div className="product-certificates-item-download">ZVA_GLM-IgG-IgM.pdf</div>
            </a>
          </div>
        </section>
      </main>
    </>
  )
}

export const query = graphql`
{
  covidIntro: file(relativePath: { eq: "covid-intro.png" }) {
    childImageSharp {
      fluid(quality: 100, maxWidth: 2200) {
        ...GatsbyImageSharpFluid_withWebp_tracedSVG
      }
    }
  }
  allYoutubeVideo {
    edges {
      node {
        id
        title
        description
        videoId
        publishedAt
        privacyStatus
      }
    }
  }
}
`

export default IndexPage
